.js-plotly-plot .plotly .modebar-btn {
  display: none !important;
}
.App{
  text-align: center;
}

/* ---------- SIGN IN ---------- */
.sign-in__wrapper {
  background-image: url("./Images//2\ \(2\).png");
   display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sign-in__backdrop {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.sign-in__wrapper form {
  width: 24rem;
  max-width: 90%;
  z-index: 1;
  animation: showSignInForm 1s;
}

.sign-in__wrapper form img {
  width: 4rem;
}

@keyframes showSignInForm {
  0%,
  30% {
    transform: translate(0, -150%);
  }
  70%,
  90% {
    transform: translate(0, 1rem);
  }
  80%,
  100% {
    transform: translate(0, 0);
  }
}
.profilepic{
  width: 40px;
  /* min-width: 56px; */
  /* height: 60px; */
  border-radius: 50% !important;
}

.react-datepicker-wrapper {
  display: flex !important;
}

.background-image {
  background-image: url('./Images//anatrxlogin.png');
  background-size: cover;
  background-repeat: no-repeat;
  
}

.background-shade {
   border: transparent;
  background-color: rgba(0, 0, 0, 0.5);  
  
  border-radius: 2%;
  
   
}
.transparent-card {
  background-color: transparent !important;
 }
 .input-bottom-border {
   border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid rgb(122, 118, 118) !important; /* Change color as needed */
  background-color: transparent !important;
  color: white;
}
.input-bottom-border::placeholder{
   color: rgb(122, 118, 118) !important;
  font-size: medium;
}

 

.filters {
  display: flex;
  gap: 2px;
  margin-bottom: 20px;
}

.summary {
  display: flex;
  gap: 1px;
 }

.card {
  border: 1px solid #ccc;
  padding: 2px;
  border-radius: 5px;
  text-align: center;
}

.patient-list table {
  width: 100%;
  border-collapse: collapse;
}
.patient-list thead {
  background-color:  #eeeeee;
}
 
.patient-list th,
.patient-list td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
  border-left: none;
  border-right: none;
  
}
.category-card {
  margin: 2px;
}

.privacy-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.privacy-header {
  border-bottom: 2px solid black;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  padding-bottom: 10px;
}

.psection {
  margin-bottom: 20px;
}

.ph2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
}

.info-section {
  padding: 10px 0;
}

.info-section h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

p {
  margin: 10px 0;
}

a {
  color: #555;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
